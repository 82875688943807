import React, { useEffect, useState } from 'react';
import { Upload, UploadProps } from 'antd';
import { RcFile } from 'antd/es/upload';
import CButton from './CButton';
import { Theme } from '../../Theme';
import { useTranslation } from 'react-i18next';
import { formatFileSize } from '../../utils/functions';

interface ImageUploaderProps {
  type: string;
  preview?: string;
  storeImages?: any[];
  onFileSelected: (files: File[]) => void;
  setAlert: any;
}

const getBase64 = (file: File, callback: (url: string | ArrayBuffer | null) => void) => {
  const reader = new FileReader();
  reader.onload = () => callback(reader.result);
  reader.readAsDataURL(file);
};

const MultiImageUploader: React.FC<ImageUploaderProps> = ({
  type,
  preview,
  storeImages,
  onFileSelected,
  setAlert,
}) => {
  const { Dragger } = Upload;
  const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (storeImages) {
      setUploadedFiles(storeImages);
    }
  }, [])
  console.log('upload file', uploadedFiles);
  const props: UploadProps = {
    name: 'file',
    multiple: true,
    beforeUpload(file) {
      console.log('file===>>>>', file);
      const isAllowedFormat = type === 'documents'
        ? ['image/png', 'image/jpeg', 'application/pdf'].includes(file.type)
        : ['image/png', 'image/jpeg'].includes(file.type);
      if (!isAllowedFormat) {
        setAlert({ message: 'Unsupported file format', type: 'error', visible: true });
        return Upload.LIST_IGNORE;
      }

      const isWithinSizeLimit = file.size / 1024 / 1024 < 5; // Check if the file is less than 5MB
      if (!isWithinSizeLimit) {
        setAlert({ message: 'File size must be less than 5MB', type: 'error', visible: true });
        return Upload.LIST_IGNORE;
      }
      if (uploadedFiles.length >= 5) {
        setAlert({ message: 'You can only upload up to 5 files', type: 'error', visible: true });
        return Upload.LIST_IGNORE;
      }
      return true;
    },
    onChange(info) {
      const { file } = info;
      console.log('file==>>>>', file);
      const newFile = file.originFileObj || file;
      if (file.status && !uploadedFiles.some(f => (f?.newFile ? f?.newFile?.uid : f?.id) === newFile?.uid)) {
        if (['image/png', 'image/jpeg', 'application/pdf'].includes(newFile.type || '')) {
          const rcFile = newFile as RcFile;
          getBase64(rcFile, (url) => {
            const data = [...uploadedFiles, { newFile, preview:  newFile?.type !== "application/pdf" ? url : "PDF" }];
            setUploadedFiles(data);
            onFileSelected(data);
          });
        } 
      }
    },
  };

  const handleRemoveFile = (file: any, fileIndex: number) => {
    if (file.preview || file.newFile?.type === "application/pdf") {
      const updatedFiles = uploadedFiles.filter((_, index) => index !== fileIndex);
      setUploadedFiles(updatedFiles);
      onFileSelected(uploadedFiles);
    } else {
      const duplicateFiles = [...uploadedFiles];
      const index = duplicateFiles.findIndex(
        ({ id }) => id === file.id
      );
      duplicateFiles[index].deleted = true;
      setUploadedFiles(duplicateFiles);
      onFileSelected(duplicateFiles);
    }

  };

  const dragAndDrop = () => (
    <>
      <p className="user-name m-0">Drag & Drop or Upload</p>
      <p className="detail-label m-0">{`File Formats : ${type === 'images' ? 'PNG, JPG' : 'PNG, JPG, PDF'}`}</p>
      <p className="detail-label m-0">File Size 5MB max</p>
    </>
  )

  return (
    <>
      <Dragger {...props} className="d-flex py-2 mx-2" style={{ width: '40%' }}>
        {type === 'images' ? (
          <div className="d-flex align-items-center justify-content-between">
            <div className='d-flex align-items-center'>
              <img src={Theme.icons.icn_gallery} alt="gallery-icon" className="category-icon mb-0" />
              <div className='mx-2' style={{ textAlign: 'left' }}>
                {dragAndDrop()}
              </div>
            </div>
            <CButton loading={false} className="upload-btn mt-1">
              Upload
            </CButton>
          </div>
        ) : (
          <>
            <img src={Theme.icons.icn_gallery} alt="gallery-icon" className="category-icon mb-0" />
            {dragAndDrop()}
            <div className="d-flex justify-content-center">
              <CButton loading={false} className="upload-btn mt-1">
                Upload
              </CButton>
            </div>
          </>
        )}

      </Dragger>

      {uploadedFiles?.length > 0 && <div className="row w-100">
        <label className='inputfield-label' style={{ display: 'block', marginBottom: '5px' }}>{t("Uploaded Images")}</label>
        {uploadedFiles?.filter(file => file.deleted !== true).map((file, index) => (
          <div className='col-lg-4' key={index}>
            <div className="d-flex align-items-center justify-content-between p-2 preview-image-container">
              <div className="d-flex align-items-center">
                {file.newFile?.type === "application/pdf" || file?.fileContentType === "application/pdf" ?
                  <div className="d-flex align-items-center justify-content-center" style={{ backgroundColor: '#EBE3F3', width: '60px', height: '60px', borderRadius: '12px' }}>
                    <img src={Theme.icons.icn_document} />
                  </div>
                  :
                  <img
                    src={file.preview ? file.preview || URL.createObjectURL(file.newFile) : process.env.REACT_APP_S3_BUCKET_URL + file.path}
                    alt={file?.newFile ? file.newFile?.name : file.originalFileName}
                    className="category-icon mb-0"
                    style={{ width: '60px', height: '60px', borderRadius: '12px' }}
                  />
                }

                <div className="mx-1">
                  <p className="detail-label m-0">{file?.newFile ? file.newFile?.name : file.originalFileName}</p>
                  <p className="detail-label m-0">{formatFileSize(file?.newFile ? file.newFile?.size : file.fileLength)}</p>
                </div>
              </div>
              <img
                src={Theme.icons.icn_cross}
                alt="remove-icon"
                className="mb-0"
                style={{ height: '16px', width: '16px', cursor: 'pointer' }}
                onClick={() => handleRemoveFile(file, index)}
              />
            </div>
          </div>
        ))}
      </div>}
    </>
  );
};

export default MultiImageUploader;
